import { useState, useEffect } from 'react';
import { Button, Spin, Form, Input, Select, message, Modal, InputNumber, ConfigProvider } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { streetList } from '../data/street';
import API from '../data/api';
import '../css/Reward.css';
import Scratch from 'react-scratch-perfect';

const EditUserForm = ({ editForm, open, onEditCreate, onEditCancel }) => {
    return (
        <Modal
            forceRender
            destroyOnClose
            open={open}
            title="填写收件信息"
            onCancel={onEditCancel}
            width={"80%"}
            footer={[
                <Button
                    style={{ borderRadius: '50px', backgroundColor: '#1e2b57', color: '#ffffff' }}
                    key='submit'
                    onClick={() => {
                        editForm
                            .validateFields()
                            .then((values) => {
                                editForm.resetFields();
                                onEditCreate(values);
                            })
                            .catch((info) => {
                                return info;

                            });
                    }}>确定</Button>
            ]}
        >
            <Form
                size='large'
                preserve={false}
                form={editForm}
                name="form_in_modal"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item name='name'
                    rules={[
                        {
                            required: true,
                            message: '请输入你的姓名',
                        },
                    ]}
                >
                    <Input style={{ borderRadius: '5px' }} placeholder="请输入你的姓名" />
                </Form.Item>
                <Form.Item name='phone'
                    rules={[
                        {
                            required: true,
                            message: '请输入你的手机号码',
                        },
                    ]}
                >
                    <InputNumber type={'tel'} style={{ width: '100%', borderRadius: '5px' }} placeholder="请输入你的手机号码" pattern="\d*" />
                </Form.Item>
                <Form.Item name='street_id'
                    rules={[
                        {
                            required: true,
                            message: '请选择街道',
                        },
                    ]}                >

                    <Select style={{ borderRadius: '5px' }} placeholder='请选择街道'
                        options={streetList.map(item => ({
                            label: item.label,
                            value: item.value,
                        }))}
                    />
                </Form.Item>
                <Form.Item name='address'
                    rules={[
                        {
                            required: true,
                            message: '请填写详细地址',
                        },
                    ]}
                >
                    <Input style={{ borderRadius: '5px' }} placeholder='请填写详细地址' />
                </Form.Item>
            </Form>
        </Modal >
    );
};

const FanzhaResult = () => {
    const [loading, setLoading] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [time, setTime] = useState(parseInt(Math.random() * (5000 - 3000 + 1) + 3000))
    const location = useLocation();
    const { userInfo } = location.state;
    const navigate = useNavigate();
    const [userInfoOpen, setUserInfoOpen] = useState(false);
    const [editForm] = Form.useForm();
    const [prize, setPrize] = useState('');
    const [rewardOpen, setRewardOpen] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [newUserInfo, setNewUserInfo] = useState(null);
    const [showBtnTitle, setShowBtnTitle] = useState(false);

    //修改地址等用户信息
    const onEditCreate = (values) => {
        values.openid = userInfo?.openid
        values.name = values.name.trim();
        values.phone = values.phone.toString().trim();
        values.address = values.address.trim();
        if (!(/^1\d{10}$/.test(values.phone))) {
            return message.warning({
                content: '手机号格式不正确，请重新输入',
                style: {
                    marginTop: '50vh'
                }
            })
        } else {
            API.fillUserInfo(values).then(
                data => {
                    if (data[0].address != '') {
                        setUserInfoOpen(false);
                        setRewardOpen(false);
                        setShowBtnTitle(false);
                        setNewUserInfo(data[0]);
                    }
                }
            ).catch(err => { return err })
        }
    }

    const getPrize = (contestId) => {
        API.getPrize({
            contestId: contestId,
            openid: userInfo.openid
        }).then(
            data => {
                setPrize(data[0].prize)
                if (data[0].lottery_status == 'NEW') {
                    setRewardOpen(true)
                }
            }
        )
    }

    useEffect(() => {
        // getPrize(2);
        if (location.state.contestId) {
            const { contestId } = location.state
            getPrize(contestId);
        } else {
            setPrize(userInfo.prize)
            if (userInfo.prize != '谢谢参与') {
                setShowBtn(true)
            }
            if (userInfo.address == '') {
                setShowBtnTitle(true)
            }
        }
    }, [])

    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
            <EditUserForm
                editForm={editForm}
                open={userInfoOpen}
                onEditCreate={onEditCreate}
                onEditCancel={() => {
                    setUserInfoOpen(false)
                }}
            />
            <div className='fanzhabg'>
                <div style={{ width: '100%', paddingBottom: '20px', textAlign: 'center' }}>
                    <div className='fanzhaRewardDiv'>
                        {rewardOpen ?
                            <div style={{ width: '50%', textAlign: 'center', padding: '25vh 0 0 0', marginLeft: '25%' }}>
                                <h3 style={{ color: '#ffffff' }}>请刮开图层，参与抽奖</h3>
                                <div style={{ width: '100%', textAlign: 'center', paddingBottom: '20px' }}>
                                    <Scratch
                                        color="#c3c3c3"
                                        round={[20, 20, 20, 20]}
                                        size={25}
                                        imgRepeat="width"
                                        percentage={50}
                                        clear={true}
                                        mode="move"
                                        className="scratch"
                                        onSuccess={() => {
                                            let ohai_user = JSON.parse(sessionStorage.getItem("ohai_user"))
                                            ohai_user.lottery_status = 'COMPLETED'
                                            sessionStorage.setItem("ohai_user", JSON.stringify(ohai_user))
                                            if (prize != '谢谢参与') {
                                                setShowBtn(true)
                                                setShowBtnTitle(true)
                                            }
                                        }}
                                    >
                                        <div className="s1">{prize}</div>
                                    </Scratch>
                                </div>
                            </div>
                            :
                            <div style={{ width: '50%', textAlign: 'center', padding: '25vh 0 0 0', marginLeft: '24%', color: '#FFD700' }}>
                                <h3 style={{ color: '#ffffff' }}>
                                    中奖结果:
                                </h3>
                                <div style={{ fontSize: '20px', padding: '20px 0 0 0' }}>{prize}</div>
                            </div>}
                    </div>
                </div>
                <div>
                    {showBtn ?
                        <button className="fanzhaImgbtn" onClick={() => {
                            if (newUserInfo) {
                                editForm.setFieldsValue({
                                    name: newUserInfo.real_name,
                                    phone: newUserInfo.phone,
                                    street_id: newUserInfo.street_id,
                                    address: newUserInfo.address,
                                })
                            } else if (userInfo.lottery_status != 'NEW') {
                                editForm.setFieldsValue({
                                    name: userInfo.real_name,
                                    phone: userInfo.phone,
                                    street_id: userInfo.address == '' ? null : userInfo.street_id,
                                    address: userInfo.address,
                                })
                            }
                            setUserInfoOpen(true)
                        }}>
                            {showBtnTitle ? <span>填写收件地址</span> : <span>查看收件地址</span>}
                        </button>
                        : null}
                </div>
            </div>
        </div >
    );
}

export default FanzhaResult;